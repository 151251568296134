@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

.App {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.site-content {
  text-align: center;
}

.header {
  width: 100%;
}

.header-content {
  display: flex;
  text-align: center;
  margin: 0 auto;
}

.headerimg {
width: 13%;
height: 100%;
margin: 5px;
align-self: center;
}

.title {
  display: inline-block;
  align-self: center;
  color: #fff;
  letter-spacing: 3px;
  width: 74%;
}

.instructions {
  letter-spacing: 1px;
  color: white;
}

.footer {
  background-color: #333333;
  height: 60px;
  display: flex;
}

.footer p {
  align-self: center;
  margin: 2px auto;
  color: white;
  letter-spacing: 2px;
}

.created {
  font-size: 1em;
}

.copyright {
  
  font-size: .8em;
}

a:link, a:visited {
  color: white;
  text-decoration: none;
  transition: .2s;
}

a:hover {
  color: rebeccapurple;
}

.site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.site-content {
  flex: 1 0 auto;
  width: 100%;
}


h1 {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.nfl-team-logo {
  height: 3.75em;
  width: 4.5em;
  /* height: 100px;
  width: 150px; */
  border: 1px solid gray;
  border-radius: 10%;
  margin-right: 5px;
  padding: 5px;
  transition: 0.2s ease;
  background-color: white;
}

.conference:hover div > span {
  opacity: 0.4;
  transition: 0.4s ease;
}

.conference:hover div > span:hover {
  opacity: 1.0;
  cursor: pointer;
  transition: 0.4s ease;
}

.card {
  width: 25%;
  margin: 0 auto;
  margin-top: 30px;
  border-radius: 25px;
  box-shadow: 10px 10px 5px black;
  letter-spacing: 1px;
}

.card h2 {
  padding-top: 20px;
  font-size: 100%;
  text-transform: uppercase;
}

.hotshots {
  background-color: #154734;
  color: #DD462C;
}

.legends {
  background-color: #470A68;
  color: #BEB07D;
}

.iron {
  background-color: #000000;
  color: #616869;
}

.express {
  background-color: #C8102E;
  color: #111D2C;
}

.apollos {
  background-color: #FE5000;
  color: #12223D;
}

.stallions {
  background-color: #0032A0;
  color: #FFFFFE;
}

.commanders {
  background-color: #651C32;
  color: #BB2531;
}

.fleet {
  background-color: #333F48;
  color: #F6C03C;
}

@media only screen and (max-width : 1215px) {
  .nfl-team-logo {
    height: 2.5em;
    width: 3em;
  }
  .card {
    width: 40%;
    margin-bottom: 15px;
  }

  .card h2 {
    font-size: 1.28em;
  }

  .card img {
    max-width: 98%;
  }
}

@media only screen and (max-width : 520px) {
  .nfl-team-logo {
    height: 1.25em;
    width: 1.5em;
  }
}
@media only screen and (max-width : 382px) {
  h1 {
    font-size: 1.3em;
  }
  .nfl-team-logo {
    height: 1.35em;
    width: 1.69em;
  }

  .card {
    width: 60%;
    margin-bottom: 15px;
  }

  .card h2 {
    font-size: 1.28em;
  }

  .card img {
    max-width: 98%;
  }

  .site-content {
    width: 98%;
  }

  .header-content {
    display: block;
    text-align: center;
    margin: 0 auto;
  }

  .headerimg {
    width: 90%;
    margin-top: 15px;
  }
    
  .title {
    width: 98%;
  }


  }